<template>
  <contact-me></contact-me>
</template>

<script>
import ContactMe from "@/components/ContactMe";
export default {
  name: "Contacts",
  components: {
    ContactMe
  }
}
</script>

<style scoped>

</style>